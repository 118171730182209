import React from 'react';


const PriceTable = () => {
    return (
        <div className="pricing-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span>Our Affordable Packages</span>
                    <h2>The Best Solutions for Our Clients</h2>
                    <div className="bar"></div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-pricing-box top-1">
                            <div className="pricing-header">
                                <h3>Standard Basic Website</h3>
                            </div>

                            <div className="price">
                            £250
                            </div>

                            <ul className="pricing-features">
                                <li>
                                    <i className='bx bx-check'></i>
                                    Unique Web Design
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    1 - 3 Page Website 
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    2 Stock Images
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Homepage Slider Banner
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Contact/Query Form
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    W3C Certified HTML
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    48 - 72 Hours TAT
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Deployment
                                </li>
                            </ul>

                            <div className="pricing-btn">
                                <a href="/contact-1">
                                    <a className="default-btn">
                                        Get Started
                                    </a>
                                </a>
                            </div>

                            <div className="pricing-shape">
                                <img src="/images/pricing-shape.png" alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Business Start up Website</h3>
                            </div>

                            <div className="price">
                                £450
                            </div>

                            <ul className="pricing-features">
                                 <li>
                                    <i className='bx bx-check'></i>
                                    Unique Web Design
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    3 - 5 Page Website
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    2 - 5 Stock Images
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Homepage Slider Banner/Unique Design
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Mobile Responsive
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    3 Banner Designs
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Google Friendly Sitemap
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    W3C Certified HTML
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    48 - 96 Hours TAT
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Contact/Query Form
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Deployment
                                </li>
                            </ul>

                            <div className="pricing-btn">
                                <a href="/contact-1">
                                    <a className="default-btn">
                                        Get Started
                                    </a>
                                </a>
                            </div>

                            <div className="pricing-shape">
                                <img src="/images/pricing-shape.png" alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-pricing-box top-2">
                            <div className="pricing-header">
                                <h3>Professional Website</h3>
                            </div>

                            <div className="price">
                            £720
                            </div>

                            <ul className="pricing-features">
                                <li>
                                    <i className='bx bx-check'></i>
                                    5 - 10 Page Website
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Unique Web Design
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    CMS/ Admin Panel Support
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    5 - 8 Stock Images
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    3 - 5 Banner Designs
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Homepage Multifunctional Slider Banner
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Mobile Responsive
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Contact/Query Form
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Google Friendly Sitemap
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    W3C Certified HTML
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    72 Hours - 1 week TAT
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Deployment
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Guaranteed Design Satisfaction
                                </li>
                            </ul>
                            
                            <div className="pricing-btn">
                                <a href="/contact-1">
                                    <a className="default-btn">
                                        Get Started
                                    </a>
                                </a>
                            </div>

                            <div className="pricing-shape">
                                <img src="/images/pricing-shape.png" alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-pricing-box top-2">
                            <div className="pricing-header">
                                <h3>Additional Options</h3>
                            </div>

                           
                            <ul className="pricing-features">
                                <li>
                                    <i className='bx bx-check'></i>
                                    Mobile Apps
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    High-End Design Websites
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Corporate Websites
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    E-Commerce Websites
                                </li>
                                <li>
                                    <i className='bx bx-check'></i>
                                    Business Websites
                                </li>
                                
                            </ul>
                            
                            <div className="pricing-btn">
                                <a href="/contact-1">
                                    <a className="default-btn">
                                        Contact US
                                    </a>
                                </a>
                            </div>

                            <div className="pricing-shape">
                                <img src="/images/pricing-shape.png" alt="image" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PriceTable;