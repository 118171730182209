import React from 'react';
import Navbar from '../components/_App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/_App/Footer';
import SubscribeForm from '../components/Common/SubscribeForm';
import TeamMember from '../components/Common/TeamMember';
import PriceTable from '../components/PricingPlans/PriceTable';
import TestimonialStyleTwo from '../components/Common/TestimonialStyleTwo';
import FunFactsTwo from '../components/Common/FunFactsTwo';
import WhatWeDoingBest from '../components/HomeFive/WhatWeDoingBest';
import LatestBlogPost from '../components/Common/LatestBlogPost';
import Partners from '../components/Common/Partners';
import YoutubeEmbed from '../components/YoutubeEmbed';
import Whatsappbtn from '../components/Common/Whatsappbtn';

const AboutOne = () => {
    return (
        <>
			<Navbar />

			<PageBanner 
                pageTitle="About Us" 
                homePageUrl="/" 
                homePageText="Home" 
                activePageText="About EYETEA" 
                BGImage="item-bg3" 
            />

            <div className="about-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-image-warp"></div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-content warp">
                                <span>About Us</span>
                                <h3>We Transform Your Creative Idea Into An Online Masterpiece</h3>
                                <div className="bar"></div>
                                <strong>With our comprehensive approach to personalized services, we provide you with specially tailored services unique to your establishment alone.</strong>
                                <p>Our approach is built on partnership and seamless integration with you. We understand that each client is unique, as a result we try our best to see from the perspective of each client, thereby projecting a portfolio that adequately reflects and exceeds expectations.This we are able to achieve through years of experience and dealings with multiple clients in several industries.</p>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>  

            {/* <YoutubeEmbed embedId="EMTZUfIbiBk" /> */}

            {/* <TeamMember /> */}

            <PriceTable />

            {/* <div className="pb-100">
                <TestimonialStyleTwo />
            </div> */}

            <FunFactsTwo />

            <WhatWeDoingBest />
            
            {/* <LatestBlogPost />
	  
			<SubscribeForm /> */}

            {/* <div className="pt-100">
                <Partners />
            </div> */}
            
            <Footer />
            <Whatsappbtn />
		</>
    )
}

export default AboutOne;