import React from 'react';
import './App.css';
import Navbar from './components/_App/Navbar';
import MainBanner from './components/HomeOne/MainBanner';
import AboutUsContent from './components/HomeOne/AboutUsContent';
import Whatsappbtn from './components/Common/Whatsappbtn';
import ServicesStyleOne from './components/Common/ServicesStyleOne';
import WeWorkForIndustries from './components/Common/WeWorkForIndustries';
import FunFacts from './components/Common/FunFacts';
// import Partners from './components/Common/Partners';
import Footer from './components/_App/Footer';
import { Routes, Route} from "react-router-dom";

import AboutOne from './pages/AboutOne';
import PricingOne from './pages/PricingOne';
import ServicesDetails from './pages/ServicesDetails';
import ContactOne from './pages/ContactOne';


import './styles/bootstrap.min.css'
import './styles/animate.css'
import './styles/boxicons.min.css'
import './styles/flaticon.css'
// import 'react-accessible-accordion/dist/fancy-example.css'
// import '../node_modules/react-modal-video/css/modal-video.min.css'
import './styles/responsive.css'



function App() {
  return (
    <>
        <Navbar />
        <MainBanner />
        <AboutUsContent />
        <ServicesStyleOne />
        <WeWorkForIndustries />
        <FunFacts />
        {/* <Partners /> */}
        <Footer />
        <Whatsappbtn />
    </>
  );
}

export default App;
