import React from 'react';


const WhatWeDoingBest = () => {
    return (
        <div className="overview-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="overview-content">
                            <h5><span>What We Do Best</span></h5>
                            <h4>Website/APP Development</h4>
                            <p>We design and maintain the most aesthetically pleasing and functional websites and APPs, from simple and basic, to complex and intricate - whatever you can imagine, we can accomplish!</p>
                            
                            <h4>Mobile App Development</h4>
                            <p>Mobile Apps have become an integral part of successful business ventures. Take your business to the next level with beautifully designed and fully functional Mobile Apps. </p>

                            <div className="overview-btn">
                                <a href="/about-1">
                                    <a className="default-btn">
                                        Contact Us
                                    </a>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="overview-image">
                            <img src="/images/overview.jpg" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatWeDoingBest;