import React from 'react';


const ServiceSidebar = () => {
    return (
        <div className="services-details-information ml-10">
            <ul className="services-list">
                <li>
                    <a href="/services-details">
                        <a className="active">Creative Web Design</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>Front-end Development</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>Back-end Development</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>Mobile App Development</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>Apple/Android Compatible Apps</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>Digital Marketing</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>CMS Development</a>
                    </a>
                </li>
                <li>
                    <a href="/services-details">
                        <a>Social Media Management</a>
                    </a>
                </li> 
                <li>
                    <a href="/services-details">
                        <a>E-commerce Solutions</a>
                    </a>
                </li> 
            </ul>

            <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li><a href="/services-details" target="_blank">PDF Download <i className='bx bxs-file-pdf'></i></a></li>
                    <li><a href="/services-details" target="_blank">Services Details.txt <i className='bx bxs-file-txt'></i></a></li>
                </ul>
            </div>

            <div className="services-contact-info">
                <h3>Contact Info</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:07988446380">07988446380</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Lincolnshire, UK
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:info@eyetea.tech">info@eyetea.tech</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar;