import React from 'react';

const AboutUsContent = () => {
    return (
        <div className="about-area pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image">
                            <img src="/images/about/about-1.png" alt="about img" />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-content p-0">
                            <span>About EYETEA</span>
                            <h3>We Transform Your Creative Idea into an Online Masterpiece</h3>
                            <div className="bar"></div>
                            <p><strong>With our comprehensive approach to personalized services, we provide you with specially tailored services unique to your establishment alone.</strong></p>
                            <p>Our approach is built on partnership and seamless integration with you. We understand that each client is different with a unique vision of their brand, as a result we try our best to see from the perspective of each client, thereby projecting a portfolio that adequately reflects and exceeds expectations.This we are able to achieve through years of experience and dealings with multiple clients in several industries.</p>

                            <div className="about-btn">
                                <a href="/about-1" className="default-btn">
                                Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    )
}

export default AboutUsContent;