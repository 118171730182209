import React, { useState } from "react";
import { Link } from 'react-router-dom';


const Navbar = () => {
    const [menu, setMenu] = React.useState(true)
 
    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0); 
    })

    // Search Modal
    const [isActiveSearchModal, setActiveSearchModal] = useState("false");
    const handleToggleSearchModal = () => {
        setActiveSearchModal(!isActiveSearchModal);
    };
 
    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <>
            <div id="navbar" className="navbar-area">
                <div className="main-navbar">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link to="/" onClick={toggleNavbar} className="navbar-brand">
                                    <img src="./images/logo.png" alt="logo" />
                            </Link>

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a href="/" className="nav-link">
                                                Home <i className='bx'></i>
                                        </a>                                        
                                    </li>
                                    
                                    {/* Added Link Below */}
                                    <li className="nav-item">
                                        <a href="/about-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                            About Us
                                        </a>
                                    </li>
                                    
                                    {/* Added Link Below */}
                                    <li className="nav-item">
                                        <a href="/PricingOne" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                            Packages
                                        </a>
                                    </li>                                    
    
                                    <li className="nav-item">
                                        {/* <a href="/#" onClick={e => e.preventDefault()} className="nav-link">
                                                Services <i className='bx bx-chevron-down'></i>
                                        </a> */}
                                            
                                            {/* <li className="nav-item">
                                                <a href="/services-3" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    Our Core Services
                                                </a>
                                            </li> */}

                                            <li className="nav-item">
                                                <a href="/services-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                     Services
                                                </a>
                                            </li>
                                    
                                    </li>
 
                                      
                                    <li className="nav-item">
                                        {/* <a href="/#" onClick={e => e.preventDefault()} className="nav-link">
                                                Contact <i className='bx bx-chevron-down'></i>
                                        </a> */}

                                            <li className="nav-item">
                                                <a href="/contact-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                    Contact Us
                                                </a>
                                            </li>
                                    </li>
                                </ul>
                            </div>

                            <div className="others-options d-flex align-items-center">
                                <div className="option-item">
                                    <i className="search-btn flaticon-loupe" onClick={handleToggleSearchModal} ></i>
                                </div>

                                <div className="option-item">
                                    <a href="/contact-1" className="default-btn">
                                         Get Quote
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
 
            {/* Search Form */}
            <div className={`search-overlay ${isActiveSearchModal ? "" : "search-overlay-active"}`}>
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>
                        
                        <div className="search-overlay-close" onClick={handleToggleSearchModal}>
                            <span className="search-overlay-close-line"></span>
                            <span className="search-overlay-close-line"></span>
                        </div>

                        <div className="search-overlay-form">
                            <form>
                                <input type="text" className="input-search" placeholder="Search here..." />
                                <button type="submit">
                                    <i className='bx bx-search-alt'></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Search Form */}
        </>
    );
}

export default Navbar;