import React from 'react';
import Navbar from '../components/_App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/_App/Footer';
import ServiceSidebar from '../components/ServicesDetails/ServiceSidebar';
import ServiceFaq from '../components/ServicesDetails/ServiceFaq';
import RelatedServices from '../components/ServicesDetails/RelatedServices';
import Whatsappbtn from '../components/Common/Whatsappbtn';

const ServicesDetails = () => {
    return (
        <>
			<Navbar />

			<PageBanner 
                pageTitle="Services Details" 
                homePageUrl="/" 
                homePageText="Home" 
                activePageText="Services Details" 
                BGImage="item-bg1" 
            />

            <div className="services-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="services-details-image">
                                <img src="/images/services-details/services-details-1.jpg" alt="image" />
                            </div>

                            <div className="services-details-desc">
                                <h3>Website Development</h3>
                                <p>Our Front-end and Back-end developers create unique solutions that perfectly mirrors and enhances each client's objectives and imagination. This results in an increase in online engagement for all our clients and a commensurate increase in revenue. With our web development solutions, your business potential skyrockets to new heights! We ensure regular updates to your website keep your business at the fore-front of an ever changing technological world!</p>

                                <h3>Mobile App Development</h3>
                                <p>Our precision-manner approach to programming ensures high performance platforms that enhances our clients businesses and dignifies their brands. We make use of various programming technologies to achieve the best Mobile App that suits your specific business needs. A major benefit is for customers, clicking your app is far quicker and easier than going through a lengthy process to access services online especially your competitor's website even if they've bookmarked it. Data capture valuable data can be gathered from apps concerning use, activity and buying habits, which are used to enhance your business potential and improve your reach to the right customers.</p>
                            
                                <div className="services-details-features">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="features-image">
                                                <img src="/images/services-details/services-details-2.jpg" alt="image" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <ul className="features-list">
                                                <li><i className="flaticon-check"></i> Offering All Jobs Guaranteed & Neatly Done</li>
                                                <li><i className="flaticon-check"></i> Swift Turn Around Time</li>
                                                <li><i className="flaticon-check"></i> Dedicated and Knowledgeable Professionals</li>
                                                <li><i className="flaticon-check"></i> Unique Design Guarantee</li>
                                                <li><i className="flaticon-check"></i> 100% Satisfaction Guaranteed</li>
                                                <li><i className="flaticon-check"></i> We Use the Most Advanced Technology and ProgrammingLanguages</li>
                                                <li><i className="flaticon-check"></i> 24/7 Post-Deployment Support</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="text-result">
                                    <h3>FAQ's</h3>
                                    <p>Not entirely sure what service will suit your business needs? Not a problem! Here are a few questions you might have... </p>
                                </div> */}

                                {/* <ServiceFaq /> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <ServiceSidebar />
                        </div>
                    </div>
                </div>
            </div>
			
            {/* <RelatedServices /> */}
            
			<Footer />
            <Whatsappbtn />
		</>
    )
}

export default ServicesDetails;