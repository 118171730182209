import React from 'react';

const Whatsappbtn = () => {
    return (
        <>
            <a
            href="https://wa.me/447988446380"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </>
    );
};

export default Whatsappbtn;