import React from 'react';

const MainBanner = () => {
    return (
        <div className="main-banner">
            <div className="main-banner-item">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="main-banner-content">
                                        <h1>We Move Businesses Forward</h1>
                                        <p>Welcome to <strong>EYETEA</strong> - the world's most innovative IT Company! We collaborate with new businesses and well-established organizations to implement your complete online presence! From <strong>Website Design</strong> to <strong>Mobile App Design/Production</strong>. We offer you the complete Business IT Experience! This allows you to focus on your core business functions, while we enhance your onine presence and help attract more customers!</p>
                                        <div className="banner-btn">
                                            <a href="/contact-1" className="default-btn">
                                                    Get Started
                                                </a>                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="main-banner-image">
                                        <img src="/images/banner/banner-image-1.png" alt="image4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-banner-shape">
                <div className="shape-1">
                    <img src="/images/banner/banner-shape-1.png" alt="image1" />
                </div>
                <div className="shape-2">
                    <img src="/images/banner/banner-shape-2.png" alt="image2" />
                </div>
                <div className="shape-3">
                    <img src="/images/banner/banner-shape-3.png" alt="image3" />
                </div>
            </div>
        </div>
    )
}

export default MainBanner;