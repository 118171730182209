import React from 'react';

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <i className="flaticon-email-1"></i>
                            </div>

                            <h3>Email US</h3>
                            <p><a href="mailto:hello@renex.com">info@eyetea.tech</a></p>
                            {/* <p><a href="mailto:support@renex.com">support@eyetea.tech</a></p> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <i className="flaticon-pin"></i>
                            </div>

                            <h3>Locate US</h3>
                            <p><a href="https://www.google.com/maps/@24.9045273,91.8523559,15z" target="_blank">Churchill House, 1 Grantley Street, Grantham Lincolnshire, United Kingdom.</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <i className="flaticon-call"></i>
                            </div>

                            <h3>Call Here</h3>
                            <p><a href="tel:07988446380">07988446380</a></p>
                            {/* <p><a href="tel:2414524526">+241</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;