import React from 'react';

const ServicesStyleOne = () => {
    return (
        <div className="services-area bg-fff5ee pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span>Services We Provide</span>
                    <h2>Our Core Services</h2>
                    <div className="bar"></div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-services">
                            <div className="image">
                                <a href="/services-details">
                                    <img src="/images/services/services-1.png" alt="imag" />
                                </a>
                            
                            </div>
                            <h3>
                                <a href="/services-details">
                                    Website Development
                                </a>
                            </h3>
                            <p>We design and maintain the most aesthetically pleasing and functional websites and APPs. From simple to intricate designs - whatever you can imagine, we can accomplish! Even if you already have a website, we help improve and manage your website 24/7 constantly providing updates to suit your business needs!</p>

                            <a href="/services-details" className="services-btn">
                                    Read More <i className='bx bx-chevrons-right'></i>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-services">
                            <div className="image">
                                <a href="/services-details">
                                    <img src="/images/services/services-2.png" alt="imag" />
                                </a>
                            </div>
                            <h3>
                                <a href="/services-details">
                                    Mobile App Development
                                </a>
                            </h3>
                            <p>Mobile Apps have become an integral part of successful business ventures. Take your business to the next level with beautifully designed and fully functional Mobile Apps. We offer to design and manage a fully functional Moble App that will enhance your ability to attract more customers and retain existing ones!</p>
                            
                            <a href="/services-details" className="services-btn">
                                    Read More <i className='bx bx-chevrons-right'></i>
                            </a>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                        <div className="single-services">
                            <div className="image">
                                <a href="/services-details">
                                    <img src="/images/services/services-3.png" alt="imag" />
                                </a>
                            </div>
                            <h3>
                                <a href="/services-details">
                                    Social Media Management
                                </a>
                            </h3>
                            <p>Social media provides an excellent opportunity for all kinds of businesses to showcase their brand and gain more customers. With our Masterclass Social media management skills, we boost your social media presence, helping your business not only expand it's reach but also achieve sales growth!</p>
                            
                            <a href="/services-details" className="services-btn">
                                    Read More <i className='bx bx-chevrons-right'></i>
                            </a>
                        </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-6">
                        <div className="single-services">
                            <div className="image">
                                <a href="/services-details">
                                    <img src="/images/services/services-4.png" alt="imag" />
                                </a>
                            </div>
                            <h3>
                                <a href="/services-details">
                                    Digital Marketing
                                </a>
                            </h3>
                            <p>Digital marketing allows your business to connect with prospective and existing customers, which is vital for business and brand awareness. Digital marketing is important because it allows you to get creative and stand out from your competitors in congested markets. We put together imressive localized digital billboards, thereby showcasing your work to every potential customer in your region.</p>
                            
                            <a href="/services-details" className="services-btn">
                                    Read More <i className='bx bx-chevrons-right'></i>
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="default-shape">
                <div className="shape1">
                    <img src="/images/default-shape/default-shape1.png" alt="imag" />
                </div>
                <div className="shape2">
                    <img src="/images/default-shape/default-shape2.png" alt="imag" />
                </div>
                <div className="shape3">
                    <img src="/images/default-shape/default-shape3.png" alt="imag" />
                </div>
                <div className="shape4">
                    <img src="/images/default-shape/default-shape4.png" alt="imag" />
                </div>
            </div>
        </div>
    )
}

export default ServicesStyleOne;