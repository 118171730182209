import React from 'react';
import Navbar from '../components/_App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/_App/Footer';
import SubscribeForm from '../components/Common/SubscribeForm';
import ContactInfo from '../components/Contact/ContactInfo';
import ContactForm from '../components/Contact/ContactForm';
import Whatsappbtn from '../components/Common/Whatsappbtn';


const Contact = () => {
    return (
        <>
			<Navbar />

			<PageBanner 
                pageTitle="Contact EYETEA" 
                homePageUrl="/" 
                homePageText="Home" 
                activePageText="Contact US" 
                BGImage="item-bg4" 
            />

            <ContactInfo />
 
            {/* <ContactForm /> */}

			{/* <SubscribeForm /> */}

			<Footer />
            <Whatsappbtn />
		</>
    )
}

export default Contact;