import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="footer-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <a href="/">
                                        <img src="/images/logo.png" alt="logo" />
                                    </a>
                                </div>

                                <p>EYETEA is a Global IT Partner with its head office located in the United Kingdom. We provide a wide range of IT services to Businesses, Companies and Organizations around the world!</p>

                                <ul className="social">
                                    <li>
                                        <a href="https://www.facebook.com/" className="facebook">
                                            <i className='bx bxl-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" className="twitter">
                                            <i className='bx bxl-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" className="pinterest">
                                            <i className='bx bxl-pinterest-alt'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/" className="linkedin">
                                            <i className='bx bxl-instagram-alt'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/" className="linkedin">
                                            <i className='bx bxl-linkedin'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Important Links</h3>
        
                                <ul className="quick-links">
                                    <li>
                                        <a href="/about-1">
                                            About Us
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="/portfolio-2">
                                            Portfolio
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="/services-details">
                                            Services
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="/blog-2">
                                            Blog
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="/contact-1">
                                            Contact
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy">
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Featured Services</h3>
        
                                <ul className="quick-links">
                                    <li>
                                        <a href="/services-details">
                                            Remote IT Management
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services-details">
                                            Website Development
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services-details">
                                            UI/UX Design
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services-details">
                                            APP Development
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services-details">
                                            Cyber Security
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/services-details">
                                            Web APP
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Information</h3>

                                <ul className="footer-contact-info">
                                    <li>
                                        <i className="flaticon-call"></i>
                                        <span>Phone</span>
                                        <a href="tel:07988446380">07988446380</a>
                                    </li>
                                    <li>
                                        <i className="flaticon-email-1"></i>
                                        <span>Email</span>
                                        <a href="mailto:info@eyetea.tech">info@eyetea.tech</a>
                                    </li>
                                    <li>
                                        <i className="flaticon-pin"></i>
                                        <span>Address</span>
                                        <a href="https://www.google.com/maps/@51.5287718,-0.2416804,11z">Churchill House, 1 Grantley Street, Grantham Lincolnshire, UK.</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright-area">
                <div className="container">
                    <div className="copyright-area-content">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>
                                    Copyright &copy; {currentYear} EYETEA.
                                </p>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li>
                                        <a href="/terms-of-service">
                                            Terms of Service
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy">
                                            Privacy Policy
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;