import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PricingOne from './pages/PricingOne';
import AboutOne from './pages/AboutOne';
import ServicesDetails from './pages/ServicesDetails';
import ContactOne from './pages/ContactOne';
import { BrowserRouter } from 'react-router-dom'
import Custom404 from './pages/404';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <Custom404/>
  },
  {
    path: "/PricingOne",
    element: <PricingOne/>,
    errorElement: <Custom404/>
  },
  {
    path: "/about-1",
    element: <AboutOne/>,
    errorElement: <Custom404/>
  },
  {
    path: "/services-details",
    element: <ServicesDetails/>,
    errorElement: <Custom404/>
  },
  {
    path: "/contact-1",
    element: <ContactOne/>,
    errorElement: <Custom404/>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}>
    <App />
  </RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
